<template>
  <div class="registration wrapper auth-layout">
    <div class="registration-form">
      <b-link to="/">
        <div class="aml-logo">
          <img src="/images/aml-logo.svg" alt="aml" />
        </div>
      </b-link>
      <div class="form-wrapper">
        <h2 class="form-title">Скидання пароля</h2>
        <h5 class="form-subtitle">
          Введіть новий пароль
        </h5>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" class="form">
            <div class="form-input-container">
              <ValidationProvider
                vid="email"
                name="адреса електронної пошти"
                rules="required|email"
                v-slot="{ errors, dirty }"
              >
                <input
                  type="email"
                  v-model="email"
                  :disabled="true"
                  class="form__input"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container">
              <ValidationProvider
                name="пароль"
                rules="required|password|confirmed:confirmation"
                v-slot="{ errors }"
              >
                <input
                  type="password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form__input form__input_primary form__input-password"
                  placeholder="Пароль"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container form-input-container__password">
              <ValidationProvider vid="confirmation" v-slot="{ errors }">
                <input
                  type="password"
                  v-model="password_confirmation"
                  :state="errors.length > 0 ? false : null"
                  class="form__input form__input_primary form__input-password"
                  placeholder="Повторіть пароль"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <button :disabled="submitting" type="submit" class="form__button">
              <span v-if="!submitting">
                Змінити Пароль
              </span>
              <span v-else>
                Відправлення...
              </span>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$route.query.email || !vm.$route.query.token)
        next({ name: "Login" });
      else next();
    });
  },
  name: "ResetPassword",
  created() {
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;
  },
  data: () => ({
    submitting: false,
    email: "",
    password: "",
    password_confirmation: "",
    token: ""
  }),
  methods: {
    onSubmit() {
      this.submitting = true;
      api
        .resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token
        })
        .then(response => {
          this.$store.dispatch("auth/saveToken", {
            token: response.data.success
          });
          this.$router
            .push({
              redirect: (window.location.href = window.location.origin)
            })
            .catch(() => {});
        })
        .catch(error => {
          if (error.response.status !== 406) {
            this.submitting = false;
            return;
          }

          if (
            !error.response.data.errors ||
            !error.response.data.errors.email
          ) {
            this.submitting = false;
            return;
          }

          this.$refs.form.setErrors({
            email: "Токен не дійсний."
          });
          this.submitting = false;
        });
    }
  }
};
</script>

<style>
body,
p {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
}
* {
  transition: all 0.2s ease;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}
</style>

<style scoped>
a > img {
  width: 100px;
}
.form-subtitle {
  margin-top: 34px;
}
</style>
